<template>
    <el-card :class="{ 'column-layout': type === 'tile' || type === 'map' || windowWidth <= 768 }">
        <div class="close-container flex-row ai-c jc-c cursor-pointer show-in-mobile" v-if="type === 'map' && showCloseIcon" @click="hidePropertyDetails">
          <el-icon :size="24" color="#0C0F4A"><CircleCloseFilled/></el-icon>
        </div>
        <div class="column-profile" @click="routeToPropertyDetails">
            <keep-alive>
                <AddressMapComponent :latlng="latlang" :property="property"/>
            </keep-alive>
            <div class="property-label-container">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="property.address"
                    placement="top"
                >
                   <span class="property-address">{{ property.address }}</span>
                </el-tooltip>
                <span class="property-postcode"><span><el-icon :size="12" color="#0C0F4A"><LocationFilled /></el-icon></span>{{ property.postcode }}</span>
            </div>
        </div>
        <div class="column-detail-container flex-row" @click="routeToPropertyDetails" :class="{'hide-in-mobile': !showAllDetail}">
            <div class="column-detail">
                <div class="item">
                    <span class="label default-text">Property type</span>
                    <span class="detail default-text">{{ propertyType }}</span>
                    <!-- <img src="@/assets/icons/insurance-icon.svg"> -->
                </div>
                <div class="item">
                    <span class="label default-text">Size</span>
                    <span class="detail default-text">
                        {{ propertySize }}
                    </span>
                    <!-- <img src="@/assets/icons/insurance-icon.svg"> -->
                </div>
            </div>
            <el-divider direction="vertical"/>
            <div class="column-detail" @click="routeToPropertyDetails">
                <div class="item">
                    <span class="label default-text">Energy rating</span>
                    <span class="detail default-text">{{ energyRating }}</span>
                </div>
                <div class="item">
                    <span class="label default-text">Projects (in progress)</span>
                    <span class="detail default-text">0</span>
                </div>
            </div>
        </div>
        <div class="column-show-detail-cta-container flex-row ai-c jc-c show-in-mobile" @click="showAllDetail = !showAllDetail">
          <p class="column-show-detail-cta-container__cta-text default-text">{{ showAllDetail ? 'View less' : 'View more' }}</p>
        </div>
        <div class="column-detail-cta-container">
            <el-popover placement="left-start" width="auto" trigger="click">
                <div class="options flex-column">
                    <div class="options__option flex-row ai-fs" v-for="(item, index) in popOverData" :key="index" @click="handleFunctionCall(item.event)">
                        <inline-svg class="download-icon" width="20" height="20" :src="item.icon"/>
                        <span class="options__label">{{ item.name }}</span>
                    </div>
                </div>
                <template #reference>
                    <el-icon :size="18" color="#0C0F4A" style="transform: rotate(90deg);" class="cursor-pointer"><MoreFilled/></el-icon>
                </template>
            </el-popover>
        </div>
    </el-card>
</template>
<script>
// eslint-disable-next-line simple-import-sort/imports
// import 'leaflet/dist/leaflet.css';

import { CircleCloseFilled, LocationFilled, MoreFilled } from '@element-plus/icons';
import { computed, defineComponent, ref } from 'vue';
import { mapActions } from 'vuex';

import AddressMapComponent from '@/modules/properties/components/AddressMapComponent.vue';
// import ViewPropertyMapModal from '@/modules/property-details-children/components/modal/ViewPropertyMapModal.vue';
import { PROPERTY_STORE } from '@/store/modules/property-details';

// import L from 'leaflet';
// import marker from '@/assets/images/marker.png';
// import { VUE_APP_LEAFLET_LAYER_URL } from '../../../config';

// tmp only
// const OS_MAP_API_KEY = 'mO9BPArB5oA2Ai8YIPs4nZQza4GhGFHh';

const NA = 'N/A';

export default defineComponent({
  components: {
    LocationFilled,
    // Picture,
    MoreFilled,
    AddressMapComponent,
    // ViewPropertyMapModal,
    CircleCloseFilled
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      /* eslint-disable global-require */
      popOverData: [
        {
          icon: `${require('@/assets/icons/home-filled-icon-2.svg')}`,
          name: 'View property',
          event: {
            name: 'routeToViewProperty',
          },
        },
        {
          icon: `${require('@/assets/icons/deactivate-icon.svg')}`,
          name: 'View property map',
          event: {
            name: 'viewMap',
          },
        },
        {
          icon: `${require('@/assets/icons/deactivate-icon.svg')}`,
          name: 'Deactivate property',
          event: {
            name: 'deactivateProperty'
          },
        },
      ],
      /* eslint-disable global-require */
      //   map: null,
      latlang: null,
      showAllDetail: false,
      NA
    };
  },
  emits: ['handle-function-call', 'deativate-property', 'view-property', 'route-to-property-details', 'hide-property-details'],
  props: ['viewType', 'property', 'showCloseIcon'],
  setup(props) {
    /* eslint-disable global-require */
    const url = ref(`${require('@/assets/images/sample-image-three.jpg')}`);
    const srcList = ref([
      `${require('@/assets/images/sample-image-three.jpg')}`,
    ]);
    /* eslint-enable global-require */

    const type = computed(() => {
      return props.viewType;
    });

    const epcData = JSON.parse(props.property?.epcData);

    return {
      url,
      srcList,
      type,
      epcData,
    };
  },

  watch: {
    windowWidth(newWidth) {
      this.windowWidth = newWidth;
    }
  },

  created() {
    const data = JSON.parse(this.property?.addressData);
    this.latlang = data?.latlng;
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },

  computed: {
    popupTemplate() {
      return `<b>${this.property?.address}</b><br />UPRN: ${this.property?.uprn}`;
    },

    propertyType() {
      return this.getPropertyInfo('built-form', 'propertyType');
    },

    propertySize() {
      return this.getPropertyInfo('total-floor-area', 'propertySize');
    },

    energyRating() {
      let energyRating = '-';
      if (this.property.postcode !== NA && this.epcData['current-energy-rating']) {
        energyRating = 'Band '.concat(this.epcData['current-energy-rating']);
      }
      if (this.property.postcode === NA && this.property.energyRating) {
        energyRating = 'Band '.concat(this.property.energyRating);
      }
      return energyRating;
    }
  },

  methods: {
    ...mapActions(PROPERTY_STORE, ['setOpenViewPropertyMapDialog', 'setIsMapView']),

    onResize() {
      this.windowWidth = window.innerWidth;
    },

    getPropertyInfo(epcName, dbName) {
      let value = '-';
      if (this.property.postcode !== NA && this.epcData[epcName]) {
        value = this.epcData[epcName];
      }
      if (this.property.postcode === NA && this.property[dbName]) {
        value = this.property[dbName];
      }
      return value;
    },

    /* eslint-disable no-param-reassign */
    handleFunctionCall(event) {
      if (event.name === 'viewMap') {
        event.latlng = this.latlang;
        event.property = this.property;
      }
      this.$emit('handle-function-call', event);
    },
    /* eslint-disable no-param-reassign */
    routeToPropertyDetails() {
      this.setIsMapView(false);
      this.$emit('route-to-property-details');
    },
    // onClose() {
    //   this.setOpenViewPropertyMapDialog(false);
    // }
    // viewMap() {
    //   this.$emit('view-map');
    // }
    hidePropertyDetails() {
      this.$emit('hide-property-details');
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;
.el-popover {
  width: auto;
}
.options {
  &__option {
    cursor: pointer;
    padding: .3rem;
    border-radius: 4px;
    // &:hover {
        // background: rgba(255, 165, 0, 0.1);
        // border: solid 2px #FFA500;
        // .options__label {
        //     color: #FFA500;
        // }
    // }

    &:last-child {
        position: relative;
        margin-top: 1rem;
        &::before {
            content: '';
            background: rgba(12, 15, 74, 0.2);
            position: absolute;
            top: -1rem;
            left:0;
            right:0;
            width: 100%;
            height: 1px;
        }
    }
  }

  &__label {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: #0C0F4A;
    margin: 0;
    // white-space: nowrap;
  }
}
.el-divider {
    margin: 0;
    height: 50px;
    width: 2px;
}
.el-card * p {
    margin: 0;
    color: #0C0F4A;
    font-family: Mulish;
    text-align: left;
}
.el-card.is-always-shadow {
    box-shadow: none;
}
.el-card:hover {
    // background: rgba(12, 15, 74, 0.02);
    border: solid 2px #FFA500;
    transition: 0.2s ease-in-out;
}
.el-card {
    border-radius: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    // max-height:150px;
    height: 100%;
    box-sizing: border-box;
    border: 2px solid transparent;
    // background:yellow;

    :deep(.el-card__body) {
        padding: .8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        // background: pink;
    }

    .column-profile {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        width:50%;
        .property-label-container {
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            height: 85px;
            box-sizing: border-box;
            padding: .5rem;

            .property-address {
                font-size: 16px;
                font-weight: 700;
                line-height: 22px;
                letter-spacing: 0.25px;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                line-height: 1.2em;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .property-postcode {
                font-size: 12px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0.25px;
                opacity: 0.5;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1rem;
                span {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
        }
    }
    .column-detail-container {
        display:flex;
        gap: 2rem;
        width: 50%;
        margin-right: 2rem;
        .column-detail {
            width:100%;
            display:flex;
            flex-direction: column;
            justify-content: space-around;

            .item {
                display: flex;
                justify-content: space-between;
                .label {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 25px;
                    letter-spacing: 0.25px;
                    color: #2A2A2A;
                }
                .detail {
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 25px;
                    letter-spacing: 0.25px;
                    color: #FFA500;
                }
            }
        }
    }
    .column-detail-cta-container {
        position: absolute;
        top: 1rem;
        right: 0.5rem;
    }
}

.el-card.column-layout {
    :deep(.el-card__body){
        display:flex;
        flex-direction: column;
        padding: 1rem;
    }

    .column-profile {
        width: 100%;
        height: 80px;
        .property-label-container {
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: .3rem;
            width: 70%;

            .property-address {
                font-size: 16px;
            }
        }
    }

    .column-detail-container {
        width: 100%;
        flex-direction: column;
        gap: .5rem;
        margin: 0;

        .el-divider {
            display: none;
        }
        .column-detail {
            width:100%;
            display:flex;
            flex-direction: column;
            justify-content: space-around;
        }
        .column-detail.grid {
            position: relative;
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem;
            .id {
                position: absolute;
                bottom: 0;
                width: 100%;
            }
            .box {
                display:flex;
                flex-direction: column-reverse;
                height: 59px;
                // gap: .5rem;
                align-items: center;
                justify-content: center;
                background: rgba(12, 15, 74, 0.05);
                border-radius: 8px;
                box-sizing: border-box;

                .label {
                    font-size: 8px;
                    color:rgba(12, 15, 74, 0.7);
                    line-height: 13px;
                }
                .detail {
                    color: #0C0F4A;
                }
            }
        }
    }
    .close-container {
      position: absolute;
      left: 9px;
      top: 8px;
      z-index: 401;
    }
}

@include  media-md-max-width() {
    .el-card {
        .column-profile {
            .property-label-container {
                .property-address {
                    font-size: 14px;
                }
            }
        }
    }
    .column-detail {
        gap: .5rem !important;
    }
    .label, .detail {
        line-height: 15px !important;
    }
}
@include media-sm-max-width() {
  .column-detail-container {
      gap: .5rem !important;
  }
  .column-show-detail-cta-container {
    width: 100%;
    position: relative;
    z-index: 4;
    // margin-top:.5rem;
    // background: red;
    padding: .5rem .5rem .8rem .5rem;
    margin-bottom: -1rem;

    &__cta-text {
      font-size: 14px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0.25px;
      text-align: center;
      color: #FFA500 !important;
    }
  }
}

@include  media-xs-max-width() {
    .el-card.column-layout {
        .column-profile {
            .property-label-container {
                .property-address {
                    font-size: 12px;
                    line-height: 17px;
                }
            }
        }
    }
}

</style>
